<template>
  <section>
    <toolbar
      hasDrawer="true"
      title="Profile"
      bgcolor="white"
      routename="menu"
      @open-drawer="$emit('open-drawer')"
    ></toolbar>
    <CentralLoader v-if="isLoading"></CentralLoader>
    <form v-else @submit.prevent="submitProfile()">
      <v-main>
        <br />
        <div class="text-center">
          <h3 class="font-weight-6">Update Your Profile</h3>
          <br />
          <v-alert type="success" dismissible v-if="successmsg">{{
            successmsg
          }}</v-alert>
          <input
            type="file"
            accept="image/*"
            @change="selectimg"
            ref="imginput"
            class="d-none"
          />
          <v-avatar color="#EFEFEF" size="120" class="overflow-visible">
            <!-- <v-btn
              v-if="formdata.loginMethod == 'STANDARD'"
              @click="$refs.imginput.click()"
              class="position-absolute"
              style="right: 0%; bottom: 0%; z-index: 99"
              x-small
              depressed
              fab
              color="#C4EEC1"
            >
              <v-icon color="white" small>mdi-pencil</v-icon>
            </v-btn> -->

            <img v-if="img" :src="img" />
            <v-icon v-else dark size="120" color="#CBCBCB">
              mdi-account
            </v-icon>
          </v-avatar>
        </div>

        <v-container class="p-4">
          <v-row class="profileform px-4">
            <v-col cols="6">
              <label>First Name</label>
              <v-text-field
                v-model="formdata.firstName"
                placeholder="eg. Chee "
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label>Last Name</label>
              <v-text-field
                v-model="formdata.lastName"
                placeholder="eg. Chen"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Mobile Number</label>
              <v-text-field
                readonly
                v-model="customer.phoneNo"
                placeholder="eg. +6893837837"
                dense
              ></v-text-field>
              <!--                </v-col>-->
              <!--              </v-row>-->
            </v-col>
            <!-- <v-col cols="12">
              <label>Do you prefer Halal or Non-Halal?</label>
              <v-select
                ref="halaselct"
                :items="halalitems"
                v-model="formdata.foodType"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <label>Age</label>
              <v-text-field
                v-model="formdata.age"
                type="number"
                placeholder="eg. 45"
                dense
              ></v-text-field>
            </v-col> -->
            <v-col cols="12">
              <label>Email Address</label>
              <v-text-field
                readonly
                v-model="customer.email"
                placeholder="eg. example@test.com"
                dense
              ></v-text-field>
            </v-col>
            <template v-if="formdata.loginMethod != 'GOOGLE'">
              <v-col cols="12">
                <label>Change Password</label>
                <v-text-field
                  v-model="formdata.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  name="input-10-2"
                  class="input-group--focused"
                  :type="show1 ? 'text' : 'password'"
                  placeholder="**********************"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Confirm Password</label>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  class="input-group--focused"
                  placeholder="**********************"
                ></v-text-field> </v-col
            ></template>
            <v-col cols="12" class="text-center">
              <v-btn
                :disabled="posting"
                type="submit"
                color="primary"
                class="addpadding"
                large
              >
                <v-icon v-if="posting">mdi-loading</v-icon>
                <template v-else>Save</template>
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-main>
    </form>
  </section>
</template>

<script>
import toolbar from '../components/partials/BackToolbar';
import CentralLoader from '@/components/Loaders/CentralLoader';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components: {
    toolbar,
    CentralLoader,
  },
  data() {
    return {
      posting: false,
      isLoading: false,
      show1: false,
      show2: false,
      img: null,
      successmsg: null,
      formdata: {},
      confirmPassword: null,
      // halalitems: ['Halal', 'Non-Halal'],
    };
  },
  watch: {
    img: function (newval) {
      this.formdata.image = newval;
    },
  },
  computed: {
    ...mapState('customer', {
      customer: (state) => state.customer,
    }),
  },
  methods: {
    ...mapActions('customer', ['updateProfile']),
    ...mapMutations('customer', ['SET_CUSTOMER']),
    selectimg(e) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = (e) => {
        this.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    submitProfile() {
      this.posting = true;
      if (this.formdata.password != this.confirmPassword) {
        alert('Passwords doesnot match');
        this.posting = false;
      } else {
        this.updateProfile(this.formdata)
          .then((res) => {
            if (res) {
              this.successmsg = 'Updated Successfully';
              this.SET_CUSTOMER({
                ...this.customer,
                lastName: this.formdata.lastName,
                firstName: this.formdata.firstName,
              });
            }
          })
          .catch((Er) => {
            console.log(Er);
          })
          .finally(() => {
            this.posting = false;
          });
      }
    },
  },
  created() {
    this.isLoading = true;
    if (this.customer) {
      this.formdata.firstName = this.customer.firstName;
      this.formdata.lastName = this.customer.lastName;
    }
    this.isLoading = false;
  },
};
</script>

<style>
.profileform label {
  font-weight: 600;
}
.save-btn {
  padding-right: 50px !important;
  padding-left: 50px !important;
}
</style>
